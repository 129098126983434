import React, { useState } from "react"
import Navbar from "./navbar"
import Footer from "./footer"
import "../styles/utleie.css"

const urlToName = {
    ynglingen: "Ynglingen",
    dyroya: "Dyrøya",
}

const Contact = ({ pageContext }) => {
    const location = pageContext.location

    const [name, setName] = useState("")
    const [email, setEmail] = useState("")
    const [phone, setPhone] = useState("")
    const [date, setDate] = useState()
    const [time, setTime] = useState("")
    const [purpose, setPurpose] = useState("")
    const [description, setDescription] = useState()

    const submit = async () => {
        const response = await fetch("/api/sendEmail", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                name,
                email,
                phone,
                date,
                time,
                purpose,
                description,
                location,
            }),
        })
        window.alert(await response.text())
    }

    return (
        <>
            <Navbar />
            <div className="calendar container">
                <h3>Utleiekalender for {urlToName[location]}</h3>
                {/*<?php echo $settings['bookingCalendar'.ucfirst($location)] ?>*/}
                <h4>Vil du leie {urlToName[location]}?</h4>
                <div className="row">
                    <div className="col-md-4">
                        <label htmlFor="name">Ditt navn</label>
                        <input
                            value={name}
                            onChange={e => setName(e.target.value)}
                            className="form-control"
                            type="text"
                            id="name"
                            placeholder="Ditt navn"
                        />
                    </div>
                    <div className="col-md-4">
                        <label htmlFor="name">Epost</label>
                        <input
                            value={email}
                            onChange={e => setEmail(e.target.value)}
                            className="form-control"
                            type="email"
                            id="email"
                            placeholder="Epost"
                        />
                    </div>
                    <div className="col-md-4">
                        <label htmlFor="name">Telefon</label>
                        <input
                            value={phone}
                            onChange={e => setPhone(e.target.value)}
                            className="form-control"
                            type="tel"
                            id="phone"
                            placeholder="Telefon"
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        <label htmlFor="date">Dato</label>
                        <input
                            value={date}
                            onChange={e => setDate(e.target.value)}
                            className="form-control"
                            type="date"
                            id="date"
                        />
                    </div>
                    <div className="col-md-6">
                        <label htmlFor="time">Tid</label>
                        <input
                            value={time}
                            onChange={e => setTime(e.target.value)}
                            className="form-control"
                            type="text"
                            id="time"
                        />
                    </div>
                </div>
                <label htmlFor="purpose">Hva vil du leie til?</label>
                <input
                    value={purpose}
                    onChange={e => setPurpose(e.target.value)}
                    className="form-control"
                    type="text"
                    id="purpose"
                />
                <label htmlFor="description">Mer informasjon:</label>
                <textarea
                    value={description}
                    onChange={e => setDescription(e.target.value)}
                    className="form-control"
                    id="description"
                />
                <input
                    type="hidden"
                    id="location"
                    value="<?php echo $location ?>"
                />
                <button
                    onClick={submit}
                    className="btn btn-default"
                    id="submit"
                >
                    Send inn
                </button>
            </div>
            <Footer />
        </>
    )
}

export default Contact
